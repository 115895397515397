.site-header {
    left: 0;
    padding-bottom: 3.2rem;
    padding-top: 3.2rem;
    position: absolute;
    right: 0;
    z-index: 9;

    &__logo {
        fill: $c-third;
        height: 4.4rem;
        width: 18.4rem;
    }
}