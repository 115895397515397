.actions-list {
    display: flex;
    margin-left: -3rem;
    margin-top: -3rem;

    &__item {
        margin-left: 3rem;
        margin-top: 3rem;

        @include breakpoint($mobile-width) {
            width: calc(50% - 3rem);
        }
    }

    &__link {
        border-radius: 7rem;
        border: 1px solid $c-link;
        display: inline-flex;
        font-size: 1.8rem;
        font-weight: 500;
        height: 5.6rem;
        justify-content: center;
        line-height: 5.8rem;
        text-decoration: none;
        text-transform: uppercase;
        transition: background-color $transition-duration, color $transition-duration;
        width: 26rem;

        @include breakpoint($mobile-width) {
            width: 100%;
        }

        @media (hover: hover) {
            &:hover {
                border-color: darken($c-link, 10%);
            }
        }

        &_download {
            background-color: $c-link;
            color: #fff;

            @media (hover: hover) {
                &:hover {
                   background-color: darken($c-link, 10%);
                }
            }
        }
    }
}