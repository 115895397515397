.download-app {
    background: url('../img/bg-download-app.svg') no-repeat 100% 50% / contain;
    margin-top: 6rem;

    @include breakpoint($mobile-width) {
        background: none;
    }

    &__inner {
        align-items: center;
        display: flex;
        justify-content: space-between;

        @include breakpoint($tablet-width) {
            flex-direction: column;
            text-align: center;
        }
    }

    &__title {
        font-weight: 400;
        margin-left: 5rem;
        margin-bottom: 1.67em;

        @include breakpoint($tablet-width) {
            margin-left: 0;
        }
    }

    &__img {
        @include breakpoint($tablet-width) {
            margin-top: 3.2rem;
        }

        @include breakpoint($mobile-width) {
            max-width: 50%;
        }
    }
}