$container-width: 1170px;

/* COLORS */

$c-main: #384650;
$c-second: #f8eef9;
$c-third: #b03e78;
$c-link: #ec988d;
$c-btn: #ec988d;

$transition-duration: 0.3s;

/* MEDIA QUERIES */

$tablet-width: 1024px;
$mobile-width: 767px;
