.site-intro {
    background: url('../img/bg-intro.svg') no-repeat 100% 0 / contain;
    padding-top: 19rem;

    @include breakpoint($mobile-width) {
        background: none;
        padding-top: 14rem;
    }

    &__inner {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-left: -9rem;
        margin-top: -3rem;

        @include breakpoint($tablet-width) {
            flex-direction: column;
            margin-left: 0;
        }
    }

    &__item {
        margin-left: 9rem;
        margin-top: 3rem;

        @include breakpoint($tablet-width) {
            margin-left: 0;
        }

        &_img {
            flex-shrink: 0;
        }
    }

    &__title {
        font-weight: 300;
        margin: -0.96em 0 0.57em;
        text-transform: uppercase;
    }

    &__description {
        margin-bottom: 5.5rem;
    }

    &__img {
        display: block;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint($mobile-width) {
            max-width: 50%;
        }
    }
}