.features-list {
    $self: &;

    margin-top: 3rem;
    padding-top: 6rem;

    &__item {
        &_first {
            position: relative;

            &:before {
                background: $c-second;
                bottom: 0;
                content: '';
                left: 0;
                mask: url('../img/mask-wave.svg') no-repeat 50% 100% / cover;
                position: absolute;
                right: 0;
                top: 0;
                z-index: -1;
            }

            #{$self} {
                &__content {
                    margin-left: 6.4rem;

                    @include breakpoint($tablet-width) {
                        margin-left: 0;
                    }
                }

                &__title-bg {
                    fill: #fff;
                }
            }
        }

        &_second {
            background: url('../img/features/bg-feature-second.svg') no-repeat 100% 50% / contain;
            margin-top: 14.5rem;

            @include breakpoint($mobile-width) {
                background-image: none;
            }

            #{$self} {
                &__inner {
                    @include breakpoint($tablet-width) {
                        flex-direction: column;
                    }
                }

                &__content {
                    margin-left: 4rem;

                    @include breakpoint($tablet-width) {
                        margin-left: 0;
                    }
                }
            }
        }

        &_third {
            background: url('../img/features/bg-feature-third.svg') no-repeat 0 50% / contain;
            margin-top: 8.5rem;

            @include breakpoint($mobile-width) {
                background-image: none;
            }

            #{$self} {
                &__content {
                    margin-left: 4rem;

                    @include breakpoint($mobile-width) {
                        margin-left: 0;
                    }
                }
            }
        }
    }

    &__inner {
        align-items: center;
        display: flex;
        justify-content: space-between;

        @include breakpoint($tablet-width) {
            flex-direction: column-reverse;
        }
    }

    &__img-wrap {
        flex-shrink: 0;
        margin-top: -5rem;

        @include breakpoint($mobile-width) {
            margin-top: 0;
        }
    }

    &__img {
        display: block;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint($mobile-width) {
            max-width: 70%;
        }
    }

    &__title {
        font-weight: 400;
        max-width: 40rem;
        position: relative;
    }

    &__title-bg {
        fill: $c-second;
        height: 4em;
        left: -1.25em;
        position: absolute;
        top: -0.625em;
        width: 4.41em;
        z-index: -1;

        @include breakpoint($mobile-width) {
            left: -0.625em;
        }
    }

    &__desc {
        color: #7c7c85;
        line-height: 1.75;
        max-width: 60rem;
    }
}