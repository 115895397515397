.download-list {
    $self: &;

    display: flex;
    margin-left: -15rem;

    @include breakpoint($tablet-width) {
        flex-direction: column;
        margin-left: 0;
    }

    &__item {
        margin-left: 15rem;

        @include breakpoint($tablet-width) {
            margin-left: 0;
        }
    }

    &__links {
        background: url('../img/bg-app.svg') no-repeat 50% / contain;
        display: inline-flex;
        flex-direction: column;
        padding: 3rem 0;
    }

    &__link {
        align-items: center;
        background: $c-third;
        border-radius: 8px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        color: #fff;
        display: inline-flex;
        font-size: 2.3rem;
        height: 6.5rem;
        margin-top: 1.9rem;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        text-decoration: none;
        transition: background-color $transition-duration;

        @include breakpoint($tablet-width) {
            justify-content: center;
        }

        @media (hover: hover) {
            &:hover {
                background-color: lighten($c-third, 10%);
            }
        }

        &_app-store {
            #{$self} {
                &__icon {
                    height: 4.1rem;
                    margin-right: 3.2rem;
                    width: 3.3rem;
                }
            }
        }

        &_google-play {
            #{$self} {
                &__icon {
                    height: 4rem;
                    width: 4.5rem;
                }
            }
        }
    }

    &__requirements {
        color: rgba(#000, 0.3);
    }

    &__icon {
        fill: #fff;
        margin-right: 1.6rem;
    }

    &__qr-code {
        background: url('../img/bg-qr-code.svg') no-repeat 50% / contain;
        padding: 3rem 2.5rem;
    }

    &__faq {
        line-height: 1.75;
        max-width: 28.5rem;
        text-align: center;

        @include breakpoint($tablet-width) {
            max-width: none;
        }
    }

    &__qr-icon {
        height: 4.3rem;
        width: 10.7rem;
    }
}