.site-footer {
    background: $c-second;
    color: $c-third;
    font-size: 1.4rem;
    mask: url('../img/mask-footer.svg') no-repeat 50% 100% / cover;
    margin-top: 20rem;

    @include breakpoint($tablet-width) {
        margin-top: 10rem;
    }

    &__inner {
        align-items: flex-end;
        display: flex;
        justify-content: space-between;
        padding-bottom: 6.5rem;
        padding-top: 6.5rem;
    }
}
